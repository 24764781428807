import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/slider/slider.module').then(m => m.SliderPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'my-course',
    loadChildren: () => import('./pages/my-course/my-course.module').then(m => m.MyCoursePageModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./pages/inbox/inbox.module').then(m => m.InboxPageModule)
  },
  {
    path: 'transaction',
    loadChildren: () => import('./pages/transaction/transaction.module').then(m => m.TransactionPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'detail',
    loadChildren: () => import('./pages/detail/detail.module').then(m => m.DetailPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'new-card',
    loadChildren: () => import('./pages/new-card/new-card.module').then(m => m.NewCardPageModule)
  },
  {
    path: 'security',
    loadChildren: () => import('./pages/security/security.module').then(m => m.SecurityPageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./pages/language/language.module').then(m => m.LanguagePageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'mentor',
    loadChildren: () => import('./pages/mentor/mentor.module').then(m => m.MentorPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'enroll',
    loadChildren: () => import('./pages/enroll/enroll.module').then(m => m.EnrollPageModule)
  },
  {
    path: 'course-detail',
    loadChildren: () => import('./pages/course-detail/course-detail.module').then(m => m.CourseDetailPageModule)
  },
  {
    path: 'call',
    loadChildren: () => import('./pages/call/call.module').then(m => m.CallPageModule)
  },
  {
    path: 'message',
    loadChildren: () => import('./pages/message/message.module').then(m => m.MessagePageModule)
  },
  {
    path: 'invite',
    loadChildren: () => import('./pages/invite/invite.module').then(m => m.InvitePageModule)
  },
  {
    path: 'top-mentors',
    loadChildren: () => import('./pages/top-mentors/top-mentors.module').then(m => m.TopMentorsPageModule)
  },
  {
    path: 'bookmark',
    loadChildren: () => import('./pages/bookmark/bookmark.module').then(m => m.BookmarkPageModule)
  },
  {
    path: 'remove-bookmark',
    loadChildren: () => import('./pages/remove-bookmark/remove-bookmark.module').then(m => m.RemoveBookmarkPageModule)
  },
  {
    path: 'popular',
    loadChildren: () => import('./pages/popular/popular.module').then(m => m.PopularPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'fill-profile',
    loadChildren: () => import('./pages/fill-profile/fill-profile.module').then(m => m.FillProfilePageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot/forgot.module').then(m => m.ForgotPageModule)
  },
  {
    path: 'new-password',
    loadChildren: () => import('./pages/new-password/new-password.module').then(m => m.NewPasswordPageModule)
  },
  {
    path: 'receipt',
    loadChildren: () => import('./pages/receipt/receipt.module').then(m => m.ReceiptPageModule)
  },
  {
    path: 'familylogin',
    loadChildren: () => import('./pages/familylogin/familylogin.module').then(m => m.FamilyloginPageModule)
  },
  {
    path: 'blogdetail',
    loadChildren: () => import('./pages/blogdetail/blogdetail.module').then(m => m.BlogdetailPageModule)
  },
  {
    path: 'marketdetail',
    loadChildren: () => import('./pages/marketdetail/marketdetail.module').then(m => m.MarketdetailPageModule)
  },
  {
    path: 'activetask',
    loadChildren: () => import('./pages/activetask/activetask.module').then(m => m.ActivetaskPageModule)
  },
  {
    path: 'family',
    loadChildren: () => import('./pages/family/family.module').then(m => m.FamilyPageModule)
  },
  {
    path: 'mytasks',
    loadChildren: () => import('./pages/mytasks/mytasks.module').then(m => m.MytasksPageModule)
  },
  {
    path: 'jobmanager',
    loadChildren: () => import('./pages/jobmanager/jobmanager.module').then(m => m.JobmanagerPageModule)
  },
  {
    path: 'jobinterest',
    loadChildren: () => import('./pages/jobinterest/jobinterest.module').then(m => m.JobinterestPageModule)
  },
  {
    path: 'joblist',
    loadChildren: () => import('./pages/joblist/joblist.module').then(m => m.JoblistPageModule)
  },
  {
    path: 'myfiles',
    loadChildren: () => import('./pages/myfiles/myfiles.module').then(m => m.MyfilesPageModule)
  },
  {
    path: 'jobreview',
    loadChildren: () => import('./pages/jobreview/jobreview.module').then(m => m.JobreviewPageModule)
  },
  {
    path: 'jobpastdue',
    loadChildren: () => import('./pages/jobpastdue/jobpastdue.module').then(m => m.JobpastduePageModule)
  },
  {
    path: 'jobactive',
    loadChildren: () => import('./pages/jobactive/jobactive.module').then(m => m.JobactivePageModule)
  },
  {
    path: 'resetpass',
    loadChildren: () => import('./pages/resetpass/resetpass.module').then(m => m.ResetpassPageModule)
  },
  {
    path: 'pendinterest',
    loadChildren: () => import('./pages/pendinterest/pendinterest.module').then(m => m.PendinterestPageModule)
  },
  {
    path: 'allblog',
    loadChildren: () => import('./pages/allblog/allblog.module').then(m => m.AllblogPageModule)
  },
  {
    path: 'joboffers',
    loadChildren: () => import('./pages/joboffers/joboffers.module').then(m => m.JoboffersPageModule)
  },
  {
    path: 'pendingoffers',
    loadChildren: () => import('./pages/pendingoffers/pendingoffers.module').then(m => m.PendingoffersPageModule)
  },
  {
    path: 'redeem',
    loadChildren: () => import('./pages/redeem/redeem.module').then(m => m.RedeemPageModule)
  },
  {
    path: 'mycoupons',
    loadChildren: () => import('./pages/mycoupons/mycoupons.module').then(m => m.MycouponsPageModule)
  },
  {
    path: 'familymember',
    loadChildren: () => import('./pages/familymember/familymember.module').then(m => m.FamilymemberPageModule)
  },
  {
    path: 'startscan',
    loadChildren: () => import('./pages/startscan/startscan.module').then(m => m.StartscanPageModule)
  },
  {
    path: 'suggest',
    loadChildren: () => import('./pages/suggest/suggest.module').then(m => m.SuggestPageModule)
  },
  {
    path: 'suggestdetails',
    loadChildren: () => import('./pages/suggestdetails/suggestdetails.module').then(m => m.SuggestdetailsPageModule)
  },
  {
    path: 'playground',
    loadChildren: () => import('./pages-family/playground/playground.module').then(m => m.PlaygroundPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutPageModule)
  },
  {
    path: 'addbank',
    loadChildren: () => import('./accounts/addbank/addbank.module').then(m => m.AddbankPageModule)
  },
  {
    path: 'locationmaps',
    loadChildren: () => import('./accounts/locationmaps/locationmaps.module').then(m => m.LocationmapsPageModule)
  },
  {
    path: 'addcredit',
    loadChildren: () => import('./accounts/addcredit/addcredit.module').then(m => m.AddcreditPageModule)
  },
  {
    path: 'familysettings',
    loadChildren: () => import('./pages/familysettings/familysettings.module').then(m => m.FamilysettingsPageModule)
  },
  {
    path: 'jobintprocess',
    loadChildren: () => import('./pages/jobintprocess/jobintprocess.module').then(m => m.JobintprocessPageModule)
  },
  {
    path: 'ownersjob',
    loadChildren: () => import('./pages/ownersjob/ownersjob.module').then(m => m.OwnersjobPageModule)
  },
  {
    path: 'jobmessage',
    loadChildren: () => import('./pages/jobmessage/jobmessage.module').then(m => m.JobmessagePageModule)
  },
  {
    path: 'preferences',
    loadChildren: () => import('./pages/preferences/preferences.module').then(m => m.PreferencesPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule)
  },
  {
    path: 'addjob',
    loadChildren: () => import('./pages/addjob/addjob.module').then(m => m.AddjobPageModule)
  },
  {
    path: 'addfamilykid',
    loadChildren: () => import('./pages/addfamilykid/addfamilykid.module').then(m => m.AddfamilykidPageModule)
  },
  {
    path: 'familyaddtask',
    loadChildren: () => import('./pages/familyaddtask/familyaddtask.module').then(m => m.FamilyaddtaskPageModule)
  },
  {
    path: 'familysuggested',
    loadChildren: () => import('./pages-family/familysuggested/familysuggested.module').then(m => m.FamilysuggestedPageModule)
  },
  {
    path: 'managependingoffers',
    loadChildren: () => import('./pages/managependingoffers/managependingoffers.module').then(m => m.ManagependingoffersPageModule)
  },
  {
    path: 'playgrdview',
    loadChildren: () => import('./pages/playgrdview/playgrdview.module').then(m => m.PlaygrdviewPageModule)
  },
  {
    path: 'familyconnectaction',
    loadChildren: () => import('./pages/familyconnectaction/familyconnectaction.module').then(m => m.FamilyconnectactionPageModule)
  },
  {
    path: 'learnmore',
    loadChildren: () => import('./pages/learnmore/learnmore.module').then(m => m.LearnmorePageModule)
  },
  {
    path: 'resources',
    loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesPageModule)
  },


  // {
  //   path: 'startlogin',
  //   loadChildren: () => import('./pages/startlogin/startlogin.module').then(m => m.StartloginPageModule)
  // },















];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
